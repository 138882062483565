import React, { Fragment } from 'react'
import { Link } from 'gatsby'

import SEO from '../../../components/seo'
import Layout from '../../../components/layout'
import Servizio from '../../../components/Servizio'

import DocumentReturnImg from '../../../images/document_return.jpg'

const paginaServizio = {
  titolo: 'Document Return',
  parent: {
    nome: 'Accessori',
    linkUrl: '/servizi/servizi-accessori',
  },
  servizi: [
    {
      id: 1,
      nome: '',
      imgUrl: DocumentReturnImg,
      paragrafi: [
        {
          id: 1,
          testo:
            'Con il nuovo servizio DocumentReturn-Service consegniamo le tue spedizioni e pensiamo noi a restituirti i documenti di cui hai bisogno debitamente compilati e firmati dal destinatario.',
        },
        {
          id: 2,
          testo:
            'DocumentReturn-Service viene espletato nel rispetto dei tempi di resa standard (24 o 48 ore) senza alcuna limitazione di dimensioni.',
        },
        {
          id: 3,
          testo:
            'GLS ti offre anche un servizio per la gestione dei documenti in formato elettronico: se ci invii il documento in formato pdf ci occupiamo noi di stamparlo, consegnarlo al destinatario e, se lo richiedi, restituirtelo firmato.',
        },
        {
          id: 4,
          testo:
            'Se sei un cliente registrato, puoi scegliere anche la modalità con cui ricevere i documenti firmati: direttamente nella tua area riservata del sito www.gls-italy.com oppure sul tuo ftp, con scadenza giornaliera.',
        },
        {
          id: 5,
          testo:
            'DocumentReturn Service anche nella sua versione digitale è compatibile con Weblabeling.',
        },
      ],
      pesoDimensioni: null,
      vantaggi: [
        {
          id: 1,
          testo: 'Riduci i costi di spedizione combinando consegna e ritiro',
        },
        {
          id: 2,
          testo: 'Ricevi automaticamente i documenti firmati dai tuoi clienti',
        },
        {
          id: 3,
          testo: 'Elimina i tempi di attesa',
        },
        {
          id: 4,
          testo: 'Non hai limiti né di peso né di dimensioni',
        },
        {
          id: 5,
          testo:
            'Prenotazione del servizio tramite telefono, e-mail, Weblabeling (per maggiori informazioni contatta la tua Sede di riferimento), o eventualmente via fax',
        },
        {
          id: 6,
          testo:
            'Gestione dei documenti firmati tramite invio elettronico in formato pdf',
        },
        {
          id: 7,
          testo:
            "Consultazione dei documenti firmati nell'area riservata del sito o su ftp",
        },
      ],
      compatibileCon: [
        {
          id: 1,
          nome: 'National Express',
          linkUrl: '/servizi/national-express',
        },
        {
          id: 2,
          nome: 'Safe Plus',
          linkUrl: '/servizi/safe-plus',
        },
      ],
      servizi: null,
    },
  ],
}

const DocumentReturn = () => {
  return (
    <Layout>
      <SEO
        title={paginaServizio.titolo}
        description={paginaServizio.servizi[0].paragrafi[0].testo}
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          {!!paginaServizio.parent && (
            <Link
              to={paginaServizio.parent.linkUrl}
              className="mr-2 text-lg text-gls-blue-600 hover:text-gls-blue-700"
            >
              {paginaServizio.parent.nome} &raquo;
            </Link>
          )}
          {paginaServizio.titolo}
        </h1>
      </section>
      <section className="bg-white lg:rounded-lg shadow-xl">
        {paginaServizio.servizi.map((servizio, index) => {
          return (
            <Fragment key={servizio.id}>
              <Servizio servizio={servizio} />
              {index < paginaServizio.servizi.length - 1 && <hr />}
            </Fragment>
          )
        })}
      </section>
    </Layout>
  )
}

export default DocumentReturn
